import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { SvgIconProps } from '@mui/material'

interface DisplayTextProps {
  title: string
  value?: any[]
  icon?: React.ComponentType<SvgIconProps>
  customElement?: JSX.Element
  underline?: boolean
}

export const DisplayText = ({
  title,
  value,
  icon: Icon,
  customElement,
  underline,
}: DisplayTextProps) => (
  <Stack spacing={1} padding={1} py={0.5}>
    <Stack
      direction={'row'}
      spacing={'12px'}
      alignContent={'center'}
      pl={'12px'}
      mb={'2px'}
    >
      {Icon && <Icon style={{ color: 'var(--color-main-pitch-black)' }} />}
      <Typography
        fontFamily={'Aeonik-Bold'}
        fontSize="12px"
        pt={'3px'}
        color="var(--color-main-pitch-black)"
      >
        {title}
      </Typography>
    </Stack>
    {value?.map((val) => (
      <Typography
        key={val}
        fontSize="14px"
        color="var(--color-main-pitch-black)"
        sx={{
          backgroundColor: 'var(--color-main-mist)',
          fontFamily: 'Aeonik-Medium',
          padding: '12px 20px',
          borderRadius: '20px',
          textDecoration: underline && val ? 'underline' : 'none',
        }}
      >
        {val ? val : 'No value set'}
      </Typography>
    ))}
    {customElement}
  </Stack>
)
